export const locales = {
  'en-US': {
    votingBarPackage: {
      close_menu: 'Close menu',
      continue: 'Continue',
      enter_code_to_vote: 'Enter code to join a live Menti',
      enter_code_here: 'Enter code here',
      join: 'Join',
    },
  },
  'pt-BR': {
    votingBarPackage: {
      close_menu: 'Fechar menu',
      continue: 'Continuar',
      enter_code_to_vote: 'Digite o código para participar de um Menti',
      enter_code_here: 'Insira o código aqui',
      join: 'Acesse',
    },
  },
  'de-DE': {
    votingBarPackage: {
      close_menu: 'Anzeige schließen',
      continue: 'Weiter',
      enter_code_to_vote:
        'Geben Sie den Code ein, um an einem Menti teilzunehmen',
      enter_code_here: 'Geben Sie den Code hier ein',
      join: 'Teilnehmen',
    },
  },
  'es-ES': {
    votingBarPackage: {
      close_menu: 'Cerrar menú',
      continue: 'Continuar',
      enter_code_to_vote: 'Introduzca el código para unirse a un Menti',
      enter_code_here: 'Introduce el código aquí',
      join: 'Acceso',
    },
  },
} as const;
