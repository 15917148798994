function fetchLocaleFromPathname(pathname: string, locales: string[]) {
  // /en-US/foo/bar -> en-US (VALID)
  // /foo/bar -> foo (INVALID)
  const locale = pathname.split('/')[1];

  if (!locale || !locales.includes(locale)) return null;

  return locale;
}

export function getLocaleFromPathname(
  locales: string[],
  defaultLocale: string,
) {
  if (typeof window === 'undefined') return defaultLocale;

  return (
    fetchLocaleFromPathname(window.location.pathname, locales) ?? defaultLocale
  );
}

export function getPathnameWithoutLocale(pathname: string, locales: string[]) {
  const existingLocale = fetchLocaleFromPathname(pathname, locales); // one of locales, null if no locale in path

  if (!existingLocale || !locales.includes(existingLocale)) {
    // there's no locale
    return pathname;
  }

  return '/' + pathname.split('/').slice(2).join('/');
}
