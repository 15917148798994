import * as React from 'react';
import { usePathname, useRouter } from '@mentimeter/next-navigation';
import { clientSidePreferredLanguage, getUserLocale } from '@mentimeter/i18n';
import { useTranslation } from 'react-i18next';
import { LOCALES, DEFAULT_LOCALE } from 'src/constants.cjs';
import { setUserLocale } from '../utils/set-user-locale';
import { switchLocale } from '../utils/switchLocale';

export const LocalizedWebsiteRedirect = () => {
  const pathname = usePathname();
  const { i18n } = useTranslation();
  const { language: locale } = i18n;
  const { push } = useRouter();

  React.useEffect(() => {
    // Get locale to redirect
    const toLocale =
      getUserLocale() ?? clientSidePreferredLanguage(LOCALES, DEFAULT_LOCALE);
    if (
      DEFAULT_LOCALE === locale &&
      toLocale !== locale &&
      typeof toLocale !== 'undefined'
    ) {
      setUserLocale(toLocale);
      push(switchLocale(pathname, toLocale));
    }
  }, [pathname, locale, push]);

  return null;
};
